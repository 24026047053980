import { notify } from 'lib/utils/honeybadgerUtils';
import { NextPageContext } from 'next';
import { map } from 'lodash';
import { BlogPost, CreatorLink } from 'lib/types';
import { Home, LoggedOutHome } from 'features/Home';
import { getUserIdFromContext } from 'server/api/services/auth';
import { serialize } from 'lib/helpers';
import { logger } from 'server/logger';
import { BlogService } from 'server/api/services/blog';
import { CreatorLinkService } from 'server/api/services/creatorLinkService';
import { getRandomLinks } from 'components/ExploreModule/utils';
import { ExploreModuleLink } from 'components/ExploreModule';
import { Job } from 'lib/types/jobs';
import { JobService } from 'server/api/services/jobs';

interface Props {
  currentUserId: number;
  blogPosts?: BlogPost[];
  exploreLinks: ExploreModuleLink[];
  jobs?: Job[];
}

export const getServerSideProps = async (context: NextPageContext) => {
  const currentUserId = await getUserIdFromContext(context);

  // This is logged out user. Just fetch blog posts and explore links
  if (!currentUserId) {
    const blogService = new BlogService();
    const results = await Promise.allSettled([
      blogService.fetchLatestPosts(4),
      CreatorLinkService.getRandom(40, 6) || [],
      JobService.searchPublicJobs({ sort: 'createdAt', size: 4, context: 'featured' }),
    ]);
    const [
      blogPosts, creatorLinks, jobs,
    ] = map(
      results, (result) => (result.status === 'fulfilled' ? result.value : []),
    );

    const exploreLinks = getRandomLinks(creatorLinks as CreatorLink[]);

    return {
      props: {
        currentUser: null,
        blogPosts: serialize(blogPosts),
        exploreLinks: serialize(exploreLinks),
        jobs: serialize((jobs as any).data),
      },
    };
  }

  try {
    const blogService = new BlogService();

    // Fetch blog posts and creator links in parallel
    const results = await Promise.allSettled([
      blogService.fetchLatestPosts(4),
      CreatorLinkService.getRandom(40, 6),
    ]);

    const [
      blogPosts, creatorLinks,
    ] = map(
      results, (result) => (result.status === 'fulfilled' ? result.value : []),
    );

    return {
      props: {
        currentUserId,
        blogPosts: serialize(blogPosts),
        exploreLinks: serialize(
          getRandomLinks(creatorLinks as CreatorLink[]),
        ),
      },
    };
  } catch (err: any) {
    logger.error(err);
    // On error we return an empty list so the home page still renders, but w/o trending posts
    const { query } = context;
    notify(err, 'Home', {
      query,
      message: 'Error fetching trending posts',
    });

    return {
      props: {
        currentUserId,
      },
    };
  }
};

const Index = ({
  currentUserId,
  blogPosts,
  exploreLinks,
  jobs,
}: Props) => {
  if (currentUserId) {
    return (
      <Home blogPosts={blogPosts} exploreLinks={exploreLinks} />
    );
  }

  return (
    <LoggedOutHome
      blogPosts={blogPosts}
      exploreLinks={exploreLinks}
      jobs={jobs}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default Index;
