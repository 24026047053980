import Info from '@mui/icons-material/Info';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { useUpgradeModal } from 'components/UpgradeModal';
import { ReactElement } from 'react';
import { userIsAdmin, userIsPro } from 'lib/helpers';
import { UserState } from 'lib/types/users';
import { isGondolaApp } from 'lib/utils/mobileAppUtils';
import { MobileTooltip } from 'components/MobileTooltip';

interface TooltipProps {
  children: ReactElement;
  title: string;
}

const userGetsTooltip = (user: UserState) => {
  if (userIsPro(user) || userIsAdmin(user)) {
    return true;
  }
  return false;
};

const TooltipForPro = ({ children, title }: TooltipProps) => {
  const currentUser = useCurrentUser();
  if (userGetsTooltip(currentUser)) {
    return (
      <MobileTooltip
        title={title}
        placement="top-end"
      >
        {children}
      </MobileTooltip>
    );
  }
  return <div>{children}</div>;
};

export const FeaturedTag = ({
  title,
}: { title: string }) => {
  const { showUpgradeModal } = useUpgradeModal();
  const currentUser = useCurrentUser();

  const openDialog = () => {
    if (!isGondolaApp) {
      // We don't show the upgrade dialog in the Gondola app because we don't have in-app purchases.
      showUpgradeModal('Gondola creators with PRO accounts that match your criteria are featured at the top of search results. Sign up for PRO to join them!');
    }
  };

  return (
    <div>
      <TooltipForPro title={title}>
        <button
          type="button"
          onClick={() => (!userGetsTooltip(currentUser) ? openDialog() : null)}
          className="text-xs uppercase bg-gradient-to-r from-[#DAA76E] to-gold text-white p-1 font-semibold flex items-center"
        >
          Featured
          <Info className="ml-1 text-sm font-semibold" />
        </button>
      </TooltipForPro>
    </div>
  );
};
