import { Job } from 'lib/types/jobs';
import { A } from 'components/A';
import { SimpleJobCard } from 'components/JobCard';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { useState, useEffect } from 'react';

interface Props {
  serverSideJobs?: Job[];
}

export const JobsModule = ({ serverSideJobs }: Props) => {
  const API = useApi();
  const currentUser = useCurrentUser();
  const [jobs, setJobs] = useState<Job[] | undefined>(serverSideJobs);

  useEffect(() => {
    const getJobs = async () => {
      try {
        const response = await API.getAllJobs({ sort: 'createdAt', size: 4 });
        setJobs(response.data);
      } catch (err) {
        // noop
      }
    };

    if (!jobs) {
      getJobs();
    }
  }, [currentUser, API]);

  if (!jobs || !jobs.length) {
    return null;
  }

  return (
    <div className="max-w-screen-xl m-auto p-4">
      <div className="flex justify-between mb-2 flex-wrap">
        <h3 className="font-bold text-lg" suppressHydrationWarning>Latest Jobs</h3>
        <div>
          <A
            href="/jobs"
            className="text-lg underline"
          >
            Find more jobs
          </A>
        </div>
      </div>
      <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {jobs.map((job: Job) => (
          <SimpleJobCard
            job={job}
            account={job.account}
          />
        ))}
      </div>
    </div>
  );
};
